import React from 'react'
import { SmartLink } from '../..'
import clsx from 'clsx'
import type { ImageType } from '../../../templates/Generic'

export const TopNavTitle = ({
  title,
  content,
  link,
  icon,
  isHeader,
  isSubheader,
  hasBorder,
  noWrap,
}: {
  title: string
  content?: string
  link?: string
  icon?: ImageType
  isHeader?: boolean
  isSubheader?: boolean
  hasBorder?: boolean
  noWrap?: boolean
}) => {
  return (
    <div
      className={clsx('flex flex-col', {
        'gap-2.5': isHeader,
        'gap-2': isSubheader,
        'gap-1.5': !isHeader && !isSubheader,
        'border-b border-b-gray-500 pb-3': hasBorder,
      })}
    >
      <SmartLink
        linkTo={link}
        className={clsx(
          'whitespace-nowrap font-MaisonNeueExtended leading-8 no-underline',
          {
            'text-xl font-bold': isHeader,
            'text-base font-bold': isSubheader,
            'text-sm font-normal': !isHeader && !isSubheader,
          },
        )}
      >
        {icon?.url && (
          <img
            src={icon.url}
            alt={icon.alt || icon.filename || ''}
            className={clsx('mr-2 inline-block', {
              'h-8 w-8': isSubheader || isHeader,
              'h-6 w-6': !isSubheader || !isHeader,
            })}
          />
        )}
        {title} <span className="text-base text-blue-700">{`>`}</span>
      </SmartLink>
      {content && (
        <p
          className={clsx('text-sm font-normal text-gray-600', {
            'xl:whitespace-nowrap': noWrap,
          })}
        >
          {content}
        </p>
      )}
    </div>
  )
}
